import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import List from '@material-ui/core/List'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import http from '../api/http'
import { linkToRecord } from '../utils/linkUtils'
import LuxerLocationIdSetting from './LuxerLocationIdSetting'

const styles = {
  toggle: {
    marginBottom: 16
  },
  table: {
    tableLayout: 'auto'
  },
  tbody: {
    height: 'inherit'
  },
  header: {
    th: {
      padding: 0
    },
    'th:first-child': {
      padding: '0 0 0 12px'
    }
  },
  cell: {
    td: {
      padding: '0 12px',
      whiteSpace: 'normal'
    },
    'td:first-child': {
      padding: '0 12px 0 16px',
      whiteSpace: 'normal'
    }
  },
  sortButton: {
    minWidth: 40
  },
  nonSortableLabel: {
    position: 'relative',
    paddingLeft: 16,
    paddingRight: 16,
    verticalAlign: 'middle',
    letterSpacing: 0,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 14,
    color: '#ccc'
  }
}

export default class Settings extends Component {
  state = {}

  async componentDidMount() {
    const response = Object.assign(
      {},
      this.state,
      await http.properties.getSettings(this.getPropertyID())
    )
    this.setState(response)
  }

  async updateState(update) {
    this.setState(
      Object.assign(
        {},
        update,
        await http.properties.updateSettings(this.getPropertyID(), update)
      )
    )
  }

  handleClick = field => {
    const value = !this.state[field]
    if (field.toLowerCase() === 'amenity_reservations' && value) {
      const new_state = {
        ...this.state,
        amenities: false,
        reservation: false,
        amenity_reservations: value
      }
      this.setState(new_state)
      return this.updateState(new_state)
    }
    this.setState({ [field]: !this.state[field] }, () => { this.updateState(this.state) })
  }

  handleChange = (field, value) => {
    const isNotZego = value !== 'Zego'

    if (field.toLowerCase() === 'packages_vendor' && isNotZego) {
      return  this.setState({ [field]: value, packages: false }, () => { this.updateState(this.state) })
    }
    this.setState({ [field]: value }, () => { this.updateState(this.state) })
  }

  getPropertyID = () => this.props.match.params.property_id

  isLuxer() {
    return String(this.state.packages_vendor).toLowerCase() === 'luxer'
  }

  shouldDisable(field, packageVendor = '') {
    switch (field.toLowerCase()) {
      case 'packages':
        const isZego = packageVendor && packageVendor === 'Zego'
        return !isZego
      case 'amenities':
      case 'reservation':
      case 'amenity_contact':
      case 'amenity_reservation_request_email':
        return this.state.amenity_reservations
    }
  }

  render() {
    return (
      <Card>
        <CardHeader
          title="Property Settings"
          titleStyle={{ fontSize: '24px' }}
          action={
            <Button
              style={{ overflow: 'inherit', float: 'right' }}
              component={Link}
              to={`${linkToRecord('/properties', this.getPropertyID())}/show`}
              color="primary">
              Back to Property
            </Button>
          }
        />
        <List>
          <Table style={styles.table}>
            <TableHead displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableCell style={styles.header['th:first-child']}>
                  <span style={styles.nonSortableLabel}>Setting</span>
                </TableCell>
                <TableCell style={styles.header['th:first-child']}>
                  <span style={styles.nonSortableLabel}>Value</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              displayRowCheckbox={false}
              className="datagrid-body"
              style={styles.tbody}>
              {Object.entries(this.state).map(([field, value], index) => {
                const switchDisabled = this.shouldDisable(
                  field,
                  this.state.packages_vendor
                )
                return (
                  <TableRow key={index}>
                    <TableCell>{field}</TableCell>
                    <TableCell>
                      {typeof value === 'boolean' ? (
                        <Switch
                          checked={switchDisabled ? false : value}
                          onChange={() => this.handleClick(field)}
                          style={styles.toggle}
                          disabled={switchDisabled}
                        />
                      ) : (
                        <TextField
                          id={`setting-${field}`}
                          defaultValue={value}
                          onChange={e => this.handleChange(field, e.target.value)
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
              {this.isLuxer() ? (
                <LuxerLocationIdSetting propertyId={this.getPropertyID()} />
              ) : null}
            </TableBody>
          </Table>
        </List>
      </Card>
    )
  }
}

Settings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      property_id: PropTypes.node
    })
  })
}
