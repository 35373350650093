import React from 'react'
import {
  List,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
  Datagrid,
  EditButton
} from 'react-admin'

import CardFilter from './cardFilter'

const CardList = props => (
    <List {...props} perPage={20} filters={<CardFilter />}>
      <Datagrid>
        <ReferenceField
          label="Name"
          source="id"
          reference="cards"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <TextField source="data.title" label="Title" sortable={false} />
        <TextField source="data.category" label="Category" sortable={false} />
        <TextField source="data.discount_description" label="Discount" sortable={false} />
        <DateField source="inserted_at" label="Created" />
        <TextField source="level" />
        <BooleanField source="global" />
        <EditButton />
      </Datagrid>
    </List>
  )

export default CardList
