import request from 'superagent'
import qs from 'querystring'
import Toast from 'react-material-toast'
import get from 'lodash'

const agent = request.agent()

// Display error toast when request fails
const toast = Toast.new({
  place: 'bottomRight',
  duration: 5
})

agent.on('error', err =>
  // eslint-disable-next-line implicit-arrow-linebreak
  toast.error(
    (err.response && err.response.body && err.response.body.friendly_error) || err.message
  )
)

const getAllPaginatedResourcesFromResponse = async (initialResponse, url) => {
  const [baseUrl, query] = url.split('?')
  const params = qs.parse(query)
  const totalPages = initialResponse.headers.get('total-pages') - 1
  const perPage = initialResponse.headers.get('per-page')

  await Promise.all(
    [...Array(totalPages)].map(async (_, index) => {
      Object.assign(params, { page: index + 2, page_size: perPage })
      const { body } = await agent.get(baseUrl).query(params)
      initialResponse.json.push(...body)
    })
  )
  initialResponse.body = JSON.stringify(initialResponse.json)
  return initialResponse
}

const setHeaders = () => {
  agent.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
  agent.set('X-Client-Type', 'support-app')
}

const uploadCardImage = async ({ id, file }) => {
  const fd = new FormData()
  fd.append('file', file)
  const response = await agent
    .post(`${window.api_url}/cards/${id}/upload-image`)
    .send(fd)
  return response.body
}

const toggleCardForProperty = async (params = {}) => {
  const response = await agent.post(
    `${window.api_url}/cards/${params.card_id}/${params.type}/${params.property_id}`
  )
  return response
}

const uploadPropertyDocument = async ({ property_id, name, file }) => {
  const fd = new FormData()
  fd.append('file', file)
  const response = await agent
    .post(
      `${window.api_url}/properties/${property_id}/documents/${name}/upload`
    )
    .send(fd)
  return response.body
}

const deletePropertyDocument = async ({ property_id, name }) => {
  const url = `${window.api_url}/properties/${property_id}/documents/${name}`
  const response = await agent.delete(url)
  return response
}

const getPropertySettings = async property_id => {
  setHeaders()
  const response = await agent.get(
    `${window.api_url}/properties/${property_id}/settings`
  )
  return response.body
}

const updatePropertySettings = async (property_id, payload) => {
  setHeaders()
  const response = await agent
    .post(`${window.api_url}/properties/${property_id}/settings`)
    .send(payload)
  return response.body
}

const sendPropertySmsInvite = (params = {}) => {
  const response = agent
    .post(`${window.api_url}/properties/${params.id}/text_invite`)
    .send(params)
  return response
}

const getDevice = async (params = {}) => {
  const response = await agent.get(`${window.api_url}/devices`).query(params)
  return response.body
}

const updateFirmware = async ({ id, type, filepath }) => {
  if (!id) {
    toast.error('Update firmware request error: id is required')
    return
  }
  const response = await agent
    .post(`${window.api_url}/devices/${id}/fw_upgrade`)
    .send({
      ...(type == null ? {} : { type }),
      ...(filepath == null ? {} : { filepath })
    })
    .on('error', err => {
      toast.error(
        `Update firmware ${id} failed due to ${get(
          err,
          'err.response.body.errors.details',
          'error'
        )}`
      )
    })
  return response.body
}

const updateScoutFirmware = async ({ identifier }) => {
  if (!identifier) {
    toast.error('Update firmware request error: identifier is required')
    return
  }
  const response = await agent
    .post(`${window.api_url}/devices/${identifier}/fw_upgrade`)
    .send({})
    .on('error', err => {
      toast.error(
        `Update Scout firmware ${identifier} failed due to ${get(
          err,
          'err.response.body.errors.details',
          'error'
        )}`
      )
    })

  return response.body
}

const unassignHub = async (params = {}) => {
  const response = await agent.put(
    `${window.api_url}/devices/${params.id}/unassign/?force=true`
  )
  return response.body
}

const ensureLockCodes = async unit_id => {
  const response = await agent.post(
    `${window.api_url}/units/${unit_id}/ensure-lock-codes`
  )
  return response
}

const getUsers = async (params = {}) => {
  const res = await agent.get(`${window.api_url}/users`).query(params)
  return res.body
}

const changeUsersSetup = async (user_id, payload) => {
  const response = await agent
    .put(`${window.api_url}/users/${user_id}/reassign`)
    .send(payload)
  return response
}

const sendResetPasswordEmail = (params = {}) => {
  const response = agent
    .post(`${window.api_url}/password-reset-request`)
    .send(params)
  return response
}

const sendUserActivationEmail = (params = {}) => {
  const response = agent.post(
    `${window.api_url}/users/${params}/send-confirmation`
  )
  return response
}

const sendLockCodeToUser = user_id => {
  const response = agent.post(
    `${window.api_url}/users/${user_id}/send_lock_code`
  )
  return response
}

const createUserChatAccess = user_id => {
  const response = agent.post(
    `${window.api_url}/users/${user_id}/chat`
  )
  return response
}

const deleteUserChatAccess = user_id => {
  const response = agent.delete(
    `${window.api_url}/users/${user_id}/chat`
  )
  return response
}

const getProperty = (params = {}) => {
  setHeaders()
  const response = agent.get(`${window.api_url}/properties/${params.id}`)
  return response
}

const updateProperty = (id, payload) => {
  const response = agent.put(`${window.api_url}/properties/${id}`).send(payload)
  return response
}

const syncPmsForProperty = (params = {}) => {
  const response = agent.post(
    `${window.api_url}/properties/${params.id}/pms/${params.type}`
  )
  return response
}

const auditSendbirdChannelsForProperty = property_id => {
  const payload = {
    'task_name': 'sendbird_user_property_audit',
    'task_args': [property_id]
  }
  const response = agent.post(`${window.api_url}/system/run-mix-task`).send(payload)
  return response
}

const syncPmsForCompanies = (
  company,
  params = { with_users: true, with_units: true }
) => {
  const response = agent
    .post(`${window.api_url}/companies/${company}/pms/properties`)
    .send(params)

  return response
}

const sendBotChatMessage = ({ bot_id, bot_token, payload }) => {
  const response = agent
    .post(`${window.api_url}/bot/${bot_id}/chat?api_token=${bot_token}`)
    .send(payload)
  return response
}

const enableResource = (resource, id, payload) => {
  const response = agent
    .post(`${window.api_url}/${resource}/${id}/enable`)
    .send(payload)
  return response
}

const createGroupSelectorsForProperty = property_id => {
  const response = agent
    .post(`${window.api_url}/system/run-mix-task`)
    .send({ task_name: `create_group_message_selectors ${property_id}` })
  return response
}

const getResourcesForUser = async userId => {
  const response = await agent.get(
    `${window.api_url}/users/${userId}/get_user_resources`
  )
  return response.body
}

const mix_tasks = {
  createGroupSelectors: createGroupSelectorsForProperty
}

const bot = {
  sendChatMessage: sendBotChatMessage
}

const users = {
  resetPasswordEmail: sendResetPasswordEmail,
  sendActivation: sendUserActivationEmail,
  get: getUsers,
  reassign: changeUsersSetup,
  sendLockCode: sendLockCodeToUser,
  getResources: getResourcesForUser,
  createChatAccess: createUserChatAccess,
  deleteChatAccess: deleteUserChatAccess
}

const devices = {
  get: getDevice,
  ensureLockCodes,
  updateFirmware,
  updateScoutFirmware
}

const hub = {
  unassign: unassignHub
}

export default {
  getAllPaginatedResourcesFromResponse,
  enableResource,
  setHeaders,
  devices,
  hub,
  users,
  bot,
  mix_tasks,
  companies: {
    syncPms: syncPmsForCompanies
  },
  properties: {
    get: getProperty,
    update: updateProperty,
    uploadDocument: uploadPropertyDocument,
    deleteDocument: deletePropertyDocument,
    getSettings: getPropertySettings,
    updateSettings: updatePropertySettings,
    sendSmsInvite: sendPropertySmsInvite,
    syncToPms: syncPmsForProperty,
    auditSendbirdChannels: auditSendbirdChannelsForProperty
  },
  cards: {
    uploadImage: uploadCardImage,
    toggleForProperty: toggleCardForProperty
  }
}

export { agent as HttpAgent }
